import axios from 'axios';
import RelationList from 'components/RequisitosRelationList/RelationEditionList';
import useRequest from 'hooks/useRequest';
import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { DefaultInput } from 'templates/input';

export default function EquiposForm({
  id,
  title,
  handleSubmit,
  handleChange,
  setData,
  loading,
  error,
  busId,
  qrgarita,
  qrcarnet,

  equ_varchar_marca,
  equ_varchar_modelo,
  equ_varchar_serie
}) {
  const { reqTokenBaseFormData, reqTokenBase } = useRequest();

  const [fileError, setFileError] = useState('');

  const [pictures, setPictures] = useState([]);

  const [currentPicture, setCurrentPicture] = useState(`${qrcarnet}/foto`);

  const saveCarnetFile = async ({ target }) => {
    setFileError('');

    if (target.files.length === 0) return;

    const limitePesoMB = 1; // Límite en MB
    const limitePesoBytes = limitePesoMB * 1024 * 1024; // Convertir a bytes

    if (target.files[0].size > limitePesoBytes) {
      return setFileError('La imagen debe tener como máximo 1 MB de peso'); // El archivo excede el límite de peso
    }

    const formData = new FormData();

    formData.append('qr', qrcarnet);
    formData.append('file', target.files[0]);

    try {
      await reqTokenBaseFormData.post('ce', formData);

      refreshPicture();
    } catch (error) {
      setFileError(error.response.data.messages.error);
    }
  };

  const getPictues = async () => {
    const res = await axios.get(`${qrcarnet}/galeria`);
    setPictures(res.data.fotos);
  };

  const refreshPicture = async () => {
    const res = await reqTokenBase.get(`administration/equipos/${id}`);
    setCurrentPicture(`${res.data.qrcarnet}/foto`);
  };

  useEffect(() => {
    if (id !== '0') getPictues();

    // eslint-disable-next-line
  }, []);

  return (
    <div className="w-100 row mx-0 g-3 align-items-start">
      <h3 className="text-center mb-3">
        {id !== '0' ? 'Editar equipo' : 'Nuevo equipo'} ({' '}
        {title.replace(/-/g, ' ')} )
      </h3>

      <div
        className={`row mx-0 ${
          id !== '0' ? 'col-12 col-md-8' : 'col-12'
        } align-items-center`}
      >
        <DefaultInput
          customClass="col-12"
          label="Marca"
          placeholder="e.j. Marca La Hora"
          value={equ_varchar_marca}
          inputName="equ_varchar_marca"
          onChange={handleChange}
          error={error.equ_varchar_marca}
        />

        <DefaultInput
          customClass="col-12"
          label="Modelo"
          placeholder="e.j. Reloj"
          value={equ_varchar_modelo}
          inputName="equ_varchar_modelo"
          onChange={handleChange}
          error={error.equ_varchar_modelo}
        />

        <DefaultInput
          customClass="col-12"
          label="Placa"
          placeholder="e.j. 01234567890"
          value={equ_varchar_serie}
          inputName="equ_varchar_serie"
          onChange={handleChange}
          error={error.equ_varchar_serie}
        />

        {id !== '0' && (
          <DefaultInput
            label="Foto de carnet"
            type="file"
            onChange={saveCarnetFile}
            error={fileError}
            required={false}
          />
        )}

        <div className="col-12 d-flex justify-content-end">
          <button
            type="button"
            onClick={handleSubmit}
            className={`btn btn-${loading ? 'secondary' : 'primary'}`}
            disabled={loading ? true : false}
          >
            {loading ? (
              <>
                <i className="fas fa-circle-notch fa-spin me-2"></i>
                Guardando...
              </>
            ) : (
              <>
                <i className="fas fa-save me-2"></i>
                Guardar
              </>
            )}
          </button>
        </div>
      </div>

      {id !== '0' && (
        <div className="col-12 col-md-4">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h5 className="text-uppercase text-center my-4">
              QR de acceso a garita
            </h5>
            <QRCode value={qrgarita || equ_varchar_marca} size={180} />

            <h5 className="text-uppercase text-center my-4">Foto actual</h5>
            <img
              style={{
                width: '180px',
                height: '180px',
                objectFit: 'cover'
              }}
              src={currentPicture}
              alt={`${equ_varchar_serie} - Foto Equipo`}
              // onError={({ target }) => (target.className = 'd-none')}
            />
          </div>
        </div>
      )}

      {id !== '0' && (
        <RelationList columns="12" id={id} busId={busId} type="equipo" />
      )}

      {id !== '0' && pictures.length ? (
        <div className="col-12 row mx-0 row-cols-3 g-3">
          <h3 className="col-12">Galería</h3>

          {pictures.map((item) => (
            <div
              key={item.fc_int_id}
              className="col d-flex align-items-center justify-content-center"
            >
              <img
                width="180"
                height="180"
                src={`${qrcarnet}/galeriafoto/${item.fe_text_name2}`}
                alt={item.fe_text_name2}
              />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}
